






































import { dashboardViewModel } from '../viewmodels/dashboard-viewmodel'
import { marketViewModel } from '@/modules/market/viewmodels/market-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'

import { walletStore } from '@/stores/wallet-store'
import { networks } from '@/constants'

@Observer
@Component({
  components: {
    HoverImage: () => import('@/modules/market/components/hover-image.vue'),
    MarketItem: () => import('@/modules/market/components/market-item.vue'),
  },
})
export default class IdoPools extends Vue {
  @Inject() providers
  dashboardViewModel = dashboardViewModel
  marketViewModel = marketViewModel
  disposers = []
  walletStore = walletStore
  tab = ''

  networks = networks

  async cancelOrder(item) {
    if (await this.marketViewModel.cancelOrder(item)) {
      await dashboardViewModel.loadInvestments()
    }
  }
}
